<template>
  <!-- 发送验证码到邮箱 -->
  <div class="forget-password">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="auto"
      class="demo-ruleForm"
      label-position="top"
    >
      <div class="title">找回密码</div>
      <!-- 输入邮箱 -->
      <el-form-item prop="mailAddress">
        <el-input
          class="restPassword"
          v-model="ruleForm.mailAddress"
          placeholder="请输入创建账号时登记的邮箱"
        ></el-input>
      </el-form-item>
      <div class="sendCode">
        <!-- 输入验证码 -->
        <el-form-item prop="code">
          <el-input
            oninput="value=value.replace(/[^\d]/g,'')"
            maxlength="6"
            class="code"
            v-model="ruleForm.code"
            placeholder="输入验证码"
          ></el-input>
        </el-form-item>
        <!-- 发送验证码 -->
        <el-form-item>
          <div v-if="isSend">
            <el-button class="send" type="primary" @click="sendCode()">发送验证码</el-button>
          </div>
          <!-- 倒计时 -->
          <div v-else>
            <el-button class="send" v-if="time > 0" type="primary"><span >{{ time }}s</span></el-button>
            <!-- 重新发送验证码 -->
            <el-button v-else class="red-btn" type="primary" @click="sendCode()">重新发送</el-button>
          </div>
        </el-form-item>
      </div>
      <!-- 下一步 -->
      <el-form-item>
        <el-button class="btn" type="primary" @click="submit()">下一步</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { validatEmailAddress, verificationCode } from "../../utils/util/test";
export default {
  data() {
    return {
      ruleForm: {
        code: "", //验证码
        mailAddress: null, //邮箱地址
        platformId: "", //平台方id
      },
      isSend: true,
      time: "", //倒计时
      //邮箱验证
      rules: {
        mailAddress: [{ validator: validatEmailAddress, trigger: "blur" }],
        code: [{ validator: verificationCode, trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState(["buttonTextColor","platformData"]),
  },
  methods: {
    //发送验证码
    sendCode() {
      this.$refs["ruleForm"].validateField("mailAddress", (valid) => {
        if (!valid) {
          this.isSend = false;
          this.time = 60;
          let timeOut = setInterval(() => {
            if (this.time < 1) {
              clearInterval(timeOut);
            } else {
              this.time--;
            }
          }, 1000);
          var params={
            "mailAddress": this.ruleForm.mailAddress,
            "platformId": this.platformData.id
          };
          this.request.getVerificationCode(params).then((res) => {
            if (res.code!=0) {
              this.$message.error(res.msg);
            }
          }).catch(() => {});
        }
      });
    },
    //输入正确验证码后跳到重置密码
    submit() {
      this.$refs["ruleForm"].validate((valid) => {
        let str = "?";
        for (let i in this.ruleForm) {
          str = str + i + "=" + this.ruleForm[i] + "&";
        }
        let that = this;
        if (valid) {
          this.request.checkVerificationCode(str).then((res) => {
            this.$router.push({
              path: "/resetPassword",
              query: {
                mailAddress: that.ruleForm.mailAddress,
                trustCode: res.data,
              },
            });
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style scoped>
.forget-password {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.code {
  border-radius: 4px !important;
  width: 257px;
}
.sendCode {
  width: 100%;
  display: flex;
  margin-bottom: 97px;
}
.demo-ruleForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;
  margin-top: 200px;
}
.send {
  margin-left: 20px;
  width: 108px;
}
.restPassword {
  width: 386px;
  border-radius: 4px !important;
}
.btn {
  width: 386px;
  border-radius: 4px !important;
}
.title {
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 54px;
}
.red-btn {
  border-radius: 4px !important;
  width: 108px;
  background-color: rgba(217, 0, 27, 0.643137254901961);
  border: 1px solid rgba(217, 0, 27, 0.643137254901961);
}
</style>